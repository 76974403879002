import { TabsMenu as TabsMenuUI } from "artisn-ui-react";
import CouponSVG from "images/TabsMenu/coupons.svg";
import { useRouter } from "next/router";
import React from "react";

import Styles from "./CouponsTabsMenuItem.styles";
import { CouponsTabsMenuItemProps as Props } from "./CouponsTabsMenuItem.types";
import useI18n from "hooks/useI18n";

const { Item } = TabsMenuUI;

const CouponsTabsMenuItem: React.FC<Props> = props => {
  const { className, hideTitle, active } = props;
  const { push } = useRouter();
  const t = useI18n();

  const onClickHandler = async () => {
    await push("/coupons");
  };

  return (
    <Styles className={`CouponsTabsMenuItem ${className}`} active={active}>
      <Item
        icon={CouponSVG}
        title={hideTitle ? undefined : t.navigation.coupons}
        onClick={onClickHandler}
      />
    </Styles>
  );
};

CouponsTabsMenuItem.defaultProps = {
  className: ""
};

export default CouponsTabsMenuItem;
