import { Image } from "artisn-ui-react";
import { events } from "artisn/analytics";
import { Banner } from "artisn/types";
import Link from "next/link";
import React from "react";

import Styles from "./BannerItem.styles";
import { BannerItemProps as Props } from "./BannerItem.types";
import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import { sanitizeText } from "utils/seo.utils";

const { PLATFORM } = CONSTANTS.GENERAL;
const { logCustomEvent } = events;

const BannerItem: React.FC<Props> = props => {
  const { banner, className } = props;
  const { isAnonymous } = useAuth();
  const { image_key, image_url, banner_title, banner_type } = banner;

  const handleBannerClick = (banner: Banner) => {
    const { banner_description, banner_title, banner_type } = banner;
    const { product_name, vendor_name, banner_category_id } = banner;

    logCustomEvent("select_ads_banner", {
      platform: PLATFORM ?? "banner without platform",
      banner_category_id:
        banner_category_id ?? "banner without banner category id",
      banner_title: banner_title ?? "banner without banner title",
      banner_description:
        banner_description ?? "banner without banner description",
      banner_type: banner_type ?? "banner without banner type",
      product_name: product_name ?? "banner without product name",
      vendor_name: vendor_name ?? "banner without vendor name",
      userType: isAnonymous ? "anonymous" : "registered"
    });
  };

  const renderBannerTypes = () => {
    if (banner_type === "URL") {
      const url = banner.banner_url ?? " ";

      return (
        <Styles className={`BannerItem ${className}`}>
          <a href={url} target="_blank" rel="noreferrer">
            <Image
              key={image_key}
              image={image_url}
              alt={banner_title}
              className="BannerItem__image BannerItem__pointer"
              onClick={() => handleBannerClick(banner)}
            />
          </a>
        </Styles>
      );
    }

    if (banner_type === "PRODUCT") {
      const { product_id, product_name } = banner;
      const sanitizedName = sanitizeText(product_name ?? "");
      const url = product_id
        ? `/products/${product_id}/${sanitizedName}`
        : "/products";

      return (
        <Styles className={`BannerItem ${className}`}>
          <Link href={url} passHref>
            <Image
              key={image_key}
              image={image_url}
              alt={banner_title}
              className="BannerItem__image BannerItem__pointer"
              onClick={() => handleBannerClick(banner)}
            />
          </Link>
        </Styles>
      );
    }

    if (banner_type === "CATEGORY") {
      const { category_id, category_name } = banner;
      const sanitizedName = sanitizeText(category_name ?? "");
      const url = category_id
        ? `/categories/${category_id}/${sanitizedName}`
        : "/categories";

      return (
        <Styles className={`BannerItem ${className}`}>
          <Link href={url} passHref>
            <Image
              key={image_key}
              image={image_url}
              alt={banner_title}
              className="BannerItem__image BannerItem__pointer"
              onClick={() => handleBannerClick(banner)}
            />
          </Link>
        </Styles>
      );
    }

    if (banner_type === "INFO") {
      return (
        <Styles className={`BannerItem ${className}`}>
          <Image
            key={image_key}
            image={image_url}
            alt={banner_title}
            className="BannerItem__image"
            onClick={() => handleBannerClick(banner)}
          />
        </Styles>
      );
    }

    return null;
  };

  return <>{renderBannerTypes()}</>;
};

BannerItem.defaultProps = {};

export default BannerItem;
