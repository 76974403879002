import styled from "styled-components";

import { CategoryGridStyledProps as Props } from "./CategoryGrid.types";
import { CategoryGridPlaceholderStyledProps as PlaceholderProps } from "./CategoryGrid.types";
import CONSTANTS from "config/constants";
import variables from "styles/util/variables";

const { mobile, tablet } = CONSTANTS.BREAKPOINTS;
const { primary } = variables.fonts;

const CategoryGridStyled = styled.div<Props>`
  font-family: ${primary};
  background-color: var(--palette-white);
  padding: 2.4rem 1.6rem;

  @media (max-width: ${mobile}px) {
    padding: 1.6rem;
  }

  .CategoryGrid {
    &__titles {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 2.4rem;
    }

    &__title {
      font-size: 2rem;
      font-weight: 600;
      color: var(--palette-black-s0-l10);
      text-transform: lowercase;
    }
    &__title-uppercase-first {
      &::first-letter {
        text-transform: uppercase;
      }
    }

    &__button {
      height: 4.6rem;
      border-radius: 10rem;
      padding: 1rem 2rem;
      font-size: 1.6rem;
      font-weight: 600;

      svg {
        margin-left: 0.8rem;
      }

      @media (max-width: ${mobile}px) {
        background-color: var(--palette-purple-s48-l39);
        border: none;

        &:hover:not([disabled]) {
          background-color: var(--palette-purple-s48-l39);
          border: none;
        }
      }
    }

    &__box {
      display: grid;
      grid-template-columns: repeat(
        ${props => (props.numberProducts ? props.numberProducts : 5)},
        1fr
      );
      overflow: hidden;
      gap: 0.8rem;

      @media (max-width: ${tablet}px) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media (max-width: ${mobile}px) {
        padding-bottom: 1.6rem;
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
`;

const CategoryGridPlaceholderStyled = styled.div<PlaceholderProps>`
  background-color: var(--palette-white);
  padding: 2.4rem 1.6rem;
  border-radius: 2.4rem;

  @media (max-width: ${mobile}px) {
    padding: 1.6rem;
  }

  @media (max-width: ${tablet}px) {
    margin-top: 9.6rem;
  }

  .CategoryGridPlaceholder {
    &__title {
      width: 20rem;
      height: 2.4rem;
      margin-bottom: 2.8rem;
    }

    &__grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      overflow: hidden;
      gap: 0.8rem;

      @media (max-width: ${tablet}px) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media (max-width: ${mobile}px) {
        padding-bottom: 1.6rem;
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &__card {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;

      &--image {
        margin: 0 auto;
        width: 12rem;
        height: 12rem;
      }

      &--title {
        width: 100%;
        height: 1.8rem;
      }

      &--description {
        width: 100%;
        height: 1.8rem;
      }

      &--price {
        width: 4rem;
        height: 1.4rem;
      }
    }
  }
`;

export { CategoryGridPlaceholderStyled };

export default CategoryGridStyled;
