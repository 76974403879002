import styled from "styled-components";

import { SignInButtonHomeStyledProps as Props } from "./SignInButtonHome.types";
import CONSTANTS from "config/constants";
const { tablet } = CONSTANTS.BREAKPOINTS;

const SignInButtonHomeStyled = styled.div<Props>`
  .SignInButtonHome {
    &__signInButton {
      width: 40rem;
      background-color: var(--palette-purple-s48-l39);
      border-width: 0;
      border-radius: 10rem;
      margin-top: 4rem;

      @media (width <= ${tablet}px) {
        width: 96%;
        margin-left: 2%;
      }

      &:hover {
        cursor: pointer;
        background-color: var(--palette-purple-s48-l39) !important;
      }

      & > svg {
        path {
          fill: var(--palette-white);
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
        text-align: left;
        width: 80%;

        & > h2 {
          font-size: 1.6rem;
          font-weight: 600;
          line-height: 2rem;
          color: var(--palette-white);
        }

        & > p {
          font-size: 1.2rem;
          font-weight: 400;
          color: var(--palette-white);
        }
      }
    }
  }
`;

export default SignInButtonHomeStyled;
