import { Image } from "artisn-ui-react";
import { events } from "artisn/analytics";
import { Banner } from "artisn/types";
import { useRouter } from "next/router";
import React from "react";

import Styles from "./CarouselBanner.styles";
import { CarouselBannerProps as Props } from "./CarouselBanner.types";
import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import { sanitizeText } from "utils/seo.utils";

const { GENERAL } = CONSTANTS;
const { PLATFORM } = GENERAL;
const { logCustomEvent } = events;

const CarouselBanner: React.FC<Props> = props => {
  const { banner } = props;
  const { push } = useRouter();
  const { isAnonymous } = useAuth();
  const { banner_description } = banner;
  const { image_url: imageUrl } = banner;

  const logBannerClick = (banner: Banner) => {
    const { banner_description, banner_title, banner_type } = banner;
    const { product_name, vendor_name, banner_category_id } = banner;

    logCustomEvent("select_product_banner", {
      platform: PLATFORM ?? "banner without platform",
      banner_category_id:
        banner_category_id ?? "banner without banner category id",
      banner_title: banner_title ?? "banner without banner title",
      banner_description:
        banner_description ?? "banner without banner description",
      banner_type: banner_type ?? "banner without banner type",
      product_name: product_name ?? "banner without product name",
      vendor_name: vendor_name ?? "banner without vendor name",
      userType: isAnonymous ? "anonymous" : "registered"
    });
  };

  // const handleButtonClick = (banner: Banner) => {
  //   logBannerClick(banner);
  //   push(`/movie/${section}`);
  // };

  const handleBannerClick = (banner: Banner) => {
    const { banner_type } = banner;

    logBannerClick(banner);

    if (banner_type === "URL") {
      const url = banner.banner_url ?? " ";
      window.open(url, "_blank");
      return;
    }

    if (banner_type === "PRODUCT") {
      const { product_id, product_name } = banner;
      const sanitizedName = sanitizeText(product_name ?? "");
      const url = product_id
        ? `/products/${product_id}/${sanitizedName}`
        : "/products";
      push(url);
      return;
    }

    if (banner_type === "CATEGORY") {
      const { category_id, category_name } = banner;
      const sanitizedName = sanitizeText(category_name ?? "");
      const url = category_id
        ? `/categories/${category_id}/${sanitizedName}`
        : "/categories";
      push(url);
      return;
    }

    return;
  };

  return (
    <Styles
      className="CarouselBanner"
      onClick={() => handleBannerClick(banner)}
    >
      <Image
        className="CarouselBanner__images"
        image={imageUrl}
        alt={banner_description}
        placeholder="blur"
      />
      <div className="CarouselBanner__content">
        {/* TODO: uncomment if need */}
        {/* {section ? (
          <Button
            type="FILLED"
            color="primary-light"
            className="CarouselBanner__btn"
            htmlType="button"
            onClick={() => handleButtonClick(banner)}
          >
            {t.common.buyNow}
          </Button>
        ) : null} */}
      </div>
    </Styles>
  );
};

CarouselBanner.defaultProps = {};

export default CarouselBanner;
