import { Clickable } from "artisn-ui-react";
import ArrowRightSvg from "images/chevron-right-black.svg";
import DogPowSvg from "images/dog-pow.svg";
import { useRouter } from "next/router";
import React from "react";

import Styles from "./ClubButton.styles";
import { ClubButtonProps as Props } from "./ClubButton.types";
import useGlobals from "contexts/globals/globals.hooks";
import useI18n from "hooks/useI18n";

const ClubButton: React.FC<Props> = props => {
  const { push } = useRouter();
  const { setJoinClub } = useGlobals();
  const t = useI18n();

  const clickHandler = () => {
    setJoinClub(true);
    push("/membership");
  };

  return (
    <Styles className="ClubButton">
      <Clickable onClick={clickHandler} className="ClubButton__container">
        <DogPowSvg />
        <div>
          <p className="ClubButton__title">{t.club.join}</p>
          <p className="ClubButton__subTitle">{t.club.club}</p>
        </div>
        <ArrowRightSvg className="ClubButton__arrow" />
      </Clickable>
    </Styles>
  );
};

ClubButton.defaultProps = {};

export default ClubButton;
