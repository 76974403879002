import { useStores } from "@simple/contexts";
import { useCatalogues } from "@simple/contexts";
import { useFetchOrderHistory } from "@simple/services";
import { useFetchCategoriesWithProducts } from "@simple/services";
import { DEFAULT_VENDOR } from "@simple/utils";
import { events } from "artisn/analytics";
import ArrowSVG from "images/arrow-right.svg";
import DogSVG from "images/dog-footprint.svg";
import React, { useEffect, useMemo } from "react";

import BannerList from "../BannerList/BannerList";
import Carousel from "../Carousel/Carousel";
import ClubButton from "../ClubButton/ClubButton";
import RepeatOrder from "../RepeatOrder/RepeatOrder";
import RepeatOrderPlaceholder from "../RepeatOrder/RepeatOrder.placeholder";
import SignInButtonHome from "../SignInButtonHome/SignInButtonHome";
import Styles from "./Home.styles";
import { HomeProps as Props } from "./Home.types";
import CategoryGrid from "components/global/CategoryGrid/CategoryGrid";
import Footer from "components/global/Footer/Footer";
import Navbar from "components/global/Navbar/Navbar";
import CONSTANTS from "config/constants";
import useAnalytics from "contexts/analytics/analytics.context.hooks";
import useAuth from "contexts/auth/auth.context.hooks";
import useGlobals from "contexts/globals/globals.hooks";
import useI18n from "hooks/useI18n";
import useRequestNavigatorGeo from "hooks/useRequestNavigatorGeo";
import useWindowSize from "hooks/useWindowSize";

const { API, BREAKPOINTS, FEATURE_FLAGS } = CONSTANTS;
const { tablet } = BREAKPOINTS;
const { PRODUCTS_PER_CATEGORY_REQUEST, CATEGORIES_PER_REQUEST } = API;
const { WITH_LOYALTY } = FEATURE_FLAGS;
const { other } = events;
const { logPageView } = other;
const bannerCategoryName = "hero_banner";
const bannerCategoryNameResponsive = "banner_principal_responsive";

const Home: React.FC<Props> = props => {
  const { width } = useWindowSize();
  const isTablet = width <= tablet;
  const { analyticsInitialized } = useAnalytics();
  const { selectedCatalogueId } = useCatalogues();
  const { defaultStore } = useStores();
  const { joinClub } = useGlobals();
  const t = useI18n();
  const auth = useAuth();
  const { isAnonymous } = auth;
  const { storeId } = defaultStore ?? {};
  useRequestNavigatorGeo();

  const orderMethods = useFetchOrderHistory(auth, selectedCatalogueId, "DONE");
  const { data: ordersDone, status: orderStatus } = orderMethods;
  const sanitizedOrders =
    ordersDone?.filter(order => order.step.code !== "CANCELED") ?? [];

  const categoryResponse = useFetchCategoriesWithProducts({
    catalogueId: selectedCatalogueId,
    storeId: storeId!,
    page: 1,
    vendorId: DEFAULT_VENDOR.id,
    size: CATEGORIES_PER_REQUEST,
    productsByGroup: PRODUCTS_PER_CATEGORY_REQUEST
  });
  const { data: categoriesPages } = categoryResponse;

  const categoriesArray = useMemo(() => {
    return categoriesPages?.pages.flatMap(page => page.data);
  }, [categoriesPages]);
  const renderRepeatOrder = () => {
    if (orderStatus === "idle" || orderStatus === "loading") {
      return <RepeatOrderPlaceholder className="Home__repeatBtn" />;
    }

    if (orderStatus === "error") {
      // TODO: CHECK ERROR MESSAGE
      return (
        <div className="Home__repeatBtn">
          <p>Error al cargar el pedido</p>
        </div>
      );
    }

    if (orderStatus === "success") {
      if (sanitizedOrders?.length === 0) return null;

      return (
        <RepeatOrder className="Home__repeatBtn" order={sanitizedOrders[0]} />
      );
    }
  };

  useEffect(() => {
    if (!analyticsInitialized) return;

    logPageView({
      name: "Home",
      userType: isAnonymous ? "anonymous" : "registered"
    });
  }, [analyticsInitialized, isAnonymous]);

  return (
    <Styles className="Home">
      <header className="Home__header">
        <Navbar showBrand showBottomSearch={false} />
        <Carousel
          className="Home__carousel"
          bannerCategoryName={
            isTablet ? bannerCategoryNameResponsive : bannerCategoryName
          }
          infinite
          animated={7000}
        />
      </header>
      <main className="Home__main">
        {isAnonymous ? <SignInButtonHome /> : null}
        <section className="Home__shortcuts">
          {renderRepeatOrder()}
          {WITH_LOYALTY && !joinClub ? (
            <>
              <ClubButton />
              <div className="Home__points">
                <DogSVG />
                <div className="Home__points__text">
                  <span>100 pts</span>
                  <span>{t.coupons.points}</span>
                </div>
                <ArrowSVG />
              </div>
            </>
          ) : null}
        </section>
        <section className="Home__products">
          <h2 className="Home__products__title">{t.common.ourProducts}</h2>
          {categoriesArray?.map(category => (
            <CategoryGrid
              key={category.categoryId}
              category={category}
              showMoreButton
              className="Home__products__grid"
            />
          ))}
        </section>
        <BannerList />
      </main>
      <footer className="Home__footer">
        <Footer />
      </footer>
    </Styles>
  );
};

Home.defaultProps = {};

export default Home;
