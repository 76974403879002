import ArrowSVG from "images/arrow-right.svg";
import DogSVG from "images/dog-footprint.svg";
import React from "react";

import Styles from "./SignInButtonHome.styles";
import { SignInButtonHomeProps as Props } from "./SignInButtonHome.types";
import Button from "components/global/Button/Button";
import useAuth from "contexts/auth/auth.context.hooks";
import useI18n from "hooks/useI18n";

const SignInButtonHome: React.FC<Props> = props => {
  const t = useI18n();
  const { toggleSignInModal } = useAuth();

  return (
    <Styles className="SignInButtonHome">
      <Button
        className="SignInButtonHome__signInButton"
        type="FILLED"
        onClick={toggleSignInModal}
      >
        <DogSVG />
        <div className="SignInButtonHome__signInButton__content">
          <h2>{t.home.enjoyBenefits}</h2>
          <p>{t.home.signInButton}</p>
        </div>
        <ArrowSVG />
      </Button>
    </Styles>
  );
};

export default SignInButtonHome;
