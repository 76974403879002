import { useFetchBanners } from "@simple/services";
import React from "react";

import BannerItem from "./BannerItem/BannerItem";
import BannerListPlaceholder from "./BannerList.placeholder";
import Styles from "./BannerList.styles";
import { BannerListProps as Props } from "./BannerList.types";

const BannerList: React.FC<Props> = props => {
  const { data: banners, status } = useFetchBanners("banner_informativo");

  const renderBanner = () => {
    if (status === "loading" || status === "idle") {
      return Array.from(new Array(2)).map((_, index) => (
        <BannerListPlaceholder key={index} className="BannerList__item" />
      ));
    }
    // TODO: Add error view
    if (status === "error") return null;
    if (status === "success") {
      return banners && banners?.length > 0
        ? banners?.map((banner, index) => {
            if (index > 1) return null;
            return (
              <BannerItem
                key={`${banner.image_key}-${index}`}
                banner={banner}
                className="BannerList__item"
              />
            );
          })
        : null;
    }
    return null;
  };

  return <Styles className="BannerList">{renderBanner()}</Styles>;
};

BannerList.defaultProps = {};

export default BannerList;
