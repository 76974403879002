import { Store } from "artisn/types";
// import TabsMenu from "components/global/TabsMenu/TabsMenu";
// import Home from "components/home/Home/Home";
import { GetStaticProps } from "next";
import Head from "next/head";
import React from "react";
import { QueryClient } from "react-query";
import { dehydrate } from "react-query/hydration";

import TabsMenu from "components/global/TabsMenu/TabsMenu";
import Home from "components/home/Home/Home";
import CONSTANTS from "config/constants";
import { prefetchDefaultStore } from "utils/cache.utils";
import { prefetchCategoriesWithProducts } from "utils/cache.utils";
import { prefetchBanners, prefetchCategories } from "utils/cache.utils";

const { CATEGORIES_PER_REQUEST, PRODUCTS_PER_CATEGORY_REQUEST } = CONSTANTS.API;

interface Props {}

const HomePage = (props: Props) => {
  return (
    <>
      <Head>
        <title>
          Pure Nature: Alimentos y Accesorios Naturales para Mascotas
        </title>
        <meta
          property="og:title"
          content="Pure Nature: Alimentos y Accesorios Naturales para Mascotas"
        />
        <meta
          name="description"
          content="Descubre alimentos orgánicos y accesorios ecológicos para tu mascota en Pure Nature. Calidad y sostenibilidad en cada producto."
        />
        <meta
          property="og:description"
          content="Descubre alimentos orgánicos y accesorios ecológicos para tu mascota en Pure Nature. Calidad y sostenibilidad en cada producto."
        />
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content="Productos Naturales para Mascotas" />
      </Head>
      <h1 style={{ position: "absolute", left: "-9999px" }}>
        Alimentación Natural para Mascotas
      </h1>
      <TabsMenu>
        <Home />
      </TabsMenu>
    </>
  );
};

export const getStaticProps: GetStaticProps = async context => {
  const queryClient = new QueryClient();

  try {
    // Banners
    const banners = prefetchBanners(queryClient, "simple_commerce_carousel");

    // Default store
    await prefetchDefaultStore(queryClient);
    const defaultStore = queryClient.getQueryData<Store>(["stores", "default"]);

    // Categories
    const categories = prefetchCategories(queryClient, defaultStore!.storeId);

    // Categories with products
    const categoriesWithProducts = prefetchCategoriesWithProducts(queryClient, {
      storeId: defaultStore!.storeId,
      productsByGroup: PRODUCTS_PER_CATEGORY_REQUEST,
      size: CATEGORIES_PER_REQUEST
    });

    await Promise.all([banners, categories, categoriesWithProducts]);
  } catch (e) {
    console.error(e.message);
  }

  return {
    props: {
      dehydratedState: JSON.parse(JSON.stringify(dehydrate(queryClient)))
    },
    revalidate: 60 * 30
  };
};

export default HomePage;
